@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-gray-100;
    /* font-family: 'Montserrat', sans-serif; */
}

.fwag-bg {
    background-image: url('./bg.png');
    background-repeat: no-repeat;
    background-position: bottom right;
}